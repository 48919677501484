.create-video-container {
  .tag {
    margin-bottom: 4px;
    &.ant-tag-checkable {
      border: 1px solid #d9d9d9;
    }
    &.ant-tag-checkable-checked {
      border: 1px solid transparent;
    }
  }

  .submit-btn {
    // margin-right: 20px;
  }

  .change-video-btn {
    // margin-right: 20px;
  }
}