// @import '../../styles/variables.scss';
@import "../../theme/GoetheVlVariables.less";

.edit-video-subtitle-container {
  margin-bottom: 20px;

  .items-container {
    // border: 1px solid map-get($colors, border);
    border-radius: 8px;
    height: 100%;
    margin-left: 10px;
    padding: 10px;

    .selected {
      background-color: @gt-green-3;
    }

    .error {
      background-color: #ffa8b0;
    }

    .range-picker {
      font-size: 10px;
    }

    .title {
      text-align: center;
    }

    .item-row {
      align-items: center;
      padding: 4px 0;
      &.subtitle{
        margin-bottom: 10px;
      }
      // &.subtitle-meta{
      //   padding-top: 4px;
      // }
        
      

      .item-number {
        display: flex;
        justify-content: flex-end;
        padding-right: 4px;
      }

      .role-select-container {
        padding-left: 5px;
        padding-right: 5px;
      }

      .delete-item-container {
        display: flex;
        justify-content: center;
      }
    }

    .add-item-container {
      justify-content: center;

      .add-item-btn {
        width: 100%;
      }
    }
  }

  .buttons-row {
    margin-top: 20px;

    .save-btn {
      margin-left: 20px;
    }
  }
}

.controls-container {
  // margin-top: 20px;
  .timeline{
    padding: 5px 0px;
  }
  .control-btn {
    font-size: 20px;
    margin-right: 10px;
  }  
}