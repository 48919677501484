// @import '../../styles/variables.scss';

.ant-page-header{
  background-color: #fff !important;
  border-bottom: 1px solid #e8e8e8;
  padding: 0;
  margin-bottom: 24px;
  .ant-page-header-heading{
    padding: 0;
    margin: 0;
    .ant-page-header-heading-title{
      font-size: 24px;
      font-weight: 500;
      color: #333;
      line-height: 1.5;
      margin-bottom: 8px;
    }
    .ant-page-header-heading-sub-title{
      font-size: 14px;
      font-weight: 400;
      color: #666;
      line-height: 1.5;
    }
  }
  .ant-page-header-footer{
    padding: 0;
    margin: 0;
    .ant-page-header-back{
      padding: 0;
      margin: 0;
      .ant-btn{
        padding: 0;
        margin: 0;
      }
    }
  }
}