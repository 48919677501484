//This file contains global overrides to standard Ant Design Components

@import "./GoetheVlVariables.less";

// Overrides for Ant Card Component: https://ant.design/components/card/

.ant-card {
  box-shadow: @gt-shadow-2;
}
.ant-layout-content{
  min-height: 90vh;
  margin: 20px;
}
.ant-card-bordered {
  border: none;
}

// Overrides for Ant Modal Component: https://ant.design/components/modal/

.ant-modal-content {
  border-radius: @gt-border-radius-lg;
}

.ant-modal-header {
  border-top-left-radius: @gt-border-radius-lg;
  border-top-right-radius: @gt-border-radius-lg;
}

