.home-page {
  .table-actions {
    padding-bottom: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    list-style: none;
    .ant-input-wrapper{
      width: 10rem !important;
    }
  }
}
